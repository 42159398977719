<template>
  <div id="LoanIndex">
    <van-notice-bar color="rgba(51, 51, 51, 1)" background="rgba(232, 247, 241, 1)">
      <van-icon name="info" color="rgba(16, 120, 117, 1)" /> 贷款申请注意事项
      <template #right-icon>
        <span v-if="tipsShow" style="color:rgba(35, 171, 114, 1)" @click="tipsShow = false">收起</span>
        <span v-else style="color:rgba(35, 171, 114, 1)" @click="tipsShow = true">查看详情</span>
      </template>
    </van-notice-bar>
    <div v-if="tipsShow" class="tips-div">
      <div><b>生源地贷款</b></div>
      <p>申请时间段 ( {{syddksqksrq}} 至 {{syddksqjsrq}} )</p>
      <p>每年贷款金额 ( {{syddkzxdkje +' ≤  贷款金额 ≤ '+syddkzddkje}} )</p>
      <p><b></b></p>
      <div><b>校园地贷款</b></div>
      <p>申请时间段 ( {{xyddksqksrq}} 至 {{xyddksqjsrq}} )</p>
      <p>每年贷款金额 ( {{xyddkzxdkje +' ≤  贷款金额 ≤ '+xyddkzddkje}} )</p>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{row.schoolYear +'年'}}</span>{{row.loanType ==='syd'?'生源地贷款':'校园地贷款'}}</span>
          </template>
          <template #label>
            <div class="custom-centent  hidden-text" style="width:95%">
              <svg-icon icon-class="date" size="15" /> 时间: {{row.createTime}}
            </div>
            <div class="custom-centent margin6 hidden-text">
              <van-icon name="refund-o" size="15" /> 金额: {{row.loanAmount}}
            </div>
          </template>
          <template #right-icon>
            <span v-if="statustag(row.approvalStatus,'success')" class="audit success-color b-color-success">审核通过</span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="audit info-color b-color-info">待审核</span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="audit error-color b-color-error">审核不通过</span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="audit warning-color b-color-warning">审核驳回</span>
            <span class="table-detail-btn detail-pos" @click="toDetail(row)" style="color:#B2B2B2"> 详情
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <span v-if="statustag(row.approvalStatus,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
            <span v-else-if="statustag(row.approvalStatus,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.approvalStatus)}}
            </span>
          </template>
          <template #right-icon>
            <van-button class="table-del-btn" :disabled="stuDisableBtn(row.approvalStatus,'del')" @click="toDel(row)" :loading="row.delLoading" plain round size="small" type="warning">删除</van-button>
            <van-button class="table-edit-btn" :disabled="stuDisableBtn(row.approvalStatus,'edit')" @click="toEdit(row)" plain round size="small" type="primary">编辑</van-button>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <!-- 提交 -->
    <div class="submit-button shadow-style">
      <van-button round block type="primary" @click="doAdd">新 增</van-button>
    </div>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { del } from "@/api/modules/student/loan";
import { getconfig } from "@/api/modules/config/cspz";

export default {
  name: "LoanIndex",
  mixins: [initData],
  dicts: ["xj_shzt", "xj_nj"],
  data() {
    return {
      tipsShow: false,
      syddksqksrq: "", //校园地贷款申请开始日期
      syddksqjsrq: "", //校园地贷款申请结束日期
      xyddksqksrq: "", //校园地贷款申请开始日期
      xyddksqjsrq: "", //校园地贷款申请结束日期
      syddkzxdkje: "", //生源地贷款最小贷款金额
      syddkzddkje: "", //生源地贷款最大贷款金额
      xyddkzxdkje: "", //校园地贷款最小贷款金额
      xyddkzddkje: "", //校园地贷款最大贷款金额,
      configData: [
        "syddksqksrq",
        "syddksqjsrq",
        "xyddksqksrq",
        "xyddksqjsrq",
        "syddkzxdkje",
        "syddkzddkje",
        "xyddkzxdkje",
        "xyddkzddkje",
      ],
    };
  },
  created() {
    getconfig({ paracodes: this.configData.join(",") }).then((res) => {
      res.forEach((item) => {
        this.configData.forEach((param) => {
          if (param === item.paracode) {
            this.$set(this, param, item.paravalue);
          }
        });
      });
    });
  },
  methods: {
    beforeInit() {
      this.url = "api/loan/student";
      this.sort = ["createTime,desc", "approvalStatus,desc"];

      return true;
    },
    onClickLeft() { },
    doAdd() {
      this.$router.push({ path: "/xs/a04/form" });
    },
    toDetail(data) {
      this.$router.push({
        path: "/xs/a04/detail",
        query: data,
      });
    },
    // 编辑
    toEdit(data) {
      this.$router.push({
        path: "/xs/a04/form",
        query: data,
      });
    },
    // 检索
    onSearch() {
      console.log("2321321");
    },
    // 删除操作
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          this.$set(data, "delLoading", true);
          del([data.id])
            .then((res) => {
              this.$notify({
                type: "success",
                message: "操作成功",
                duration: 2000,
              });
              this.init();
              this.$set(data, "delLoading", false);
            })
            .catch((e) => {
              this.$set(data, "delLoading", false);
            });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
    stuDisableBtn(spzt, type) {
      let status = parseInt(spzt);
      if ([9].indexOf(status) > -1) {
        return true;
      }
      if (type === "del") {
        if ([2, 3, 4, 5].indexOf(status) > -1) {
          return true;
        }
      } else if (type === "edit") {
        if ([101, 201, 301, 2, 3].indexOf(status) > -1) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang='scss' scoped>
@import '~@/assets/styles/student/loan.scss';
.van-list {
  margin-bottom: 54px;
}
.van-cell__title {
  font-size: 16px;
  text-align: left;
  min-width: 70%;
}
.detail-pos {
  position: absolute;
  top: 50%;
  right: 15px;
}
#opt-style {
  .van-cell__title {
    min-width: 50%;
    font-size: 14px;
    line-height: 32px;
  }
}
</style>
